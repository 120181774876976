import React from "react";
import { CircularProgress } from "@mui/material";

function SvgIcon(props: any) {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      style={{
        fontSize: 20,
        userSelect: "none",
        width: "1em",
        height: "1em",
        display: "inline-block",
        fill: "currentColor",
        flexShrink: 0,
      }}
      {...props}
    />
  );
}

function CheckIcon() {
  return (
    <SvgIcon>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23985 18.6518 7.55544C19.5308 8.87103 20 10.4178 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
        fill="#54C081"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9583 9.46025L10.4987 15.9199L7.03906 12.4603L7.9583 11.541L10.4987 14.0814L16.0391 8.54102L16.9583 9.46025Z"
        fill="#54C081"
      />
    </SvgIcon>
  );
}

const WarningIcon: React.FC = () => (
  <SvgIcon>
    <path d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
  </SvgIcon>
);

const ErrorIcon: React.FC = () => (
  <SvgIcon>
    <path
      d="M12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12L13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10535 12.2652 7 12 7C11.7348 7 11.4804 7.10535 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8L11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13ZM11.62 16.92C11.8635 17.02 12.1365 17.02 12.38 16.92C12.5027 16.8724 12.6149 16.801 12.71 16.71C12.7983 16.6128 12.8694 16.5012 12.92 16.38C12.976 16.2613 13.0034 16.1312 13 16C13.0008 15.8684 12.9755 15.7379 12.9258 15.6161C12.876 15.4943 12.8027 15.3834 12.71 15.29C12.6128 15.2017 12.5012 15.1306 12.38 15.08C12.2285 15.0178 12.064 14.9937 11.9011 15.0099C11.7381 15.0261 11.5816 15.0821 11.4453 15.1729C11.309 15.2638 11.1971 15.3867 11.1195 15.5309C11.0418 15.6751 11.0008 15.8362 11 16C11.0037 16.2648 11.1073 16.5184 11.29 16.71C11.3851 16.801 11.4972 16.8724 11.62 16.92ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8078 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92893C17.6725 3.5304 15.8907 2.57799 13.9509 2.19214C12.0111 1.80629 10.0004 2.00432 8.17316 2.7612C6.3459 3.51808 4.78412 4.7998 3.6853 6.44429C2.58649 8.08879 2 10.0222 2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17316 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5822 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5822 4 12C4 9.87826 4.84285 7.84343 6.34314 6.34314C7.84343 4.84285 9.87827 4 12 4Z"
      fill="#D3625B"
    />
  </SvgIcon>
);

const InfoIcon: React.FC = () => (
  <SvgIcon>
    <path
      d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,
        0 22,12A10,10 0 0,0 12,2Z"
    />
  </SvgIcon>
);

const defaultIconVariants: Record<string, React.ReactNode> = {
  default: undefined,
  success: <CheckIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
  loading: <CircularProgress size={20} sx={{ color: "#ffffff" }} />,
};

export default defaultIconVariants;
