import { SvgIcon, SvgIconProps } from "@mui/material";

export default function NoDataIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 127 112" {...props}>
      <path
        d="M118.908 63.9278L96.9166 34.3004C96.464 33.6897 95.8739 33.1936 95.1939 32.852C94.5139 32.5105 93.7629 32.3332 93.0016 32.3342H26.5951C25.8338 32.3332 25.0828 32.5105 24.4028 32.852C23.7228 33.1936 23.1327 33.6897 22.6801 34.3004L0.688599 63.9278V100.033C0.688599 101.997 1.47087 103.881 2.86333 105.27C4.25579 106.659 6.14437 107.44 8.1136 107.44H111.497C113.466 107.44 115.354 106.659 116.747 105.27C118.139 103.881 118.922 101.997 118.922 100.033L118.908 63.9278Z"
        fill="#4F5A84"
      />
      <path
        d="M111.497 108.1H8.10006C5.95181 108.1 3.89154 107.248 2.3725 105.733C0.85345 104.218 6.10538e-05 102.162 6.10538e-05 100.019V63.9278C-0.00189252 63.7829 0.0430824 63.6412 0.128308 63.5238L22.1198 33.8964C22.6374 33.2023 23.3103 32.6381 24.0848 32.2489C24.8594 31.8596 25.7143 31.656 26.5816 31.6542H92.9881C93.8553 31.656 94.7102 31.8596 95.4848 32.2489C96.2593 32.6381 96.9322 33.2023 97.4498 33.8964L119.441 63.5238C119.527 63.6412 119.572 63.7829 119.57 63.9278V100.033C119.566 102.169 118.715 104.217 117.202 105.728C115.689 107.24 113.638 108.092 111.497 108.1ZM1.36356 64.15V100.033C1.36356 101.819 2.07472 103.531 3.34059 104.794C4.60646 106.057 6.32335 106.766 8.11356 106.766H111.497C113.287 106.766 115.004 106.057 116.27 104.794C117.535 103.531 118.247 101.819 118.247 100.033V64.15L96.3901 34.691C95.9985 34.1658 95.4896 33.739 94.9037 33.4445C94.3178 33.1499 93.6712 32.9957 93.0151 32.9941H26.5951C25.939 32.9957 25.2923 33.1499 24.7064 33.4445C24.1205 33.739 23.6116 34.1658 23.2201 34.691L1.36356 64.15Z"
        fill="#4F5A84"
      />
      <path
        d="M118.908 63.9279V100.033C118.908 101.997 118.126 103.881 116.733 105.27C115.341 106.659 113.452 107.44 111.483 107.44H8.10005C6.13082 107.44 4.24224 106.659 2.84978 105.27C1.45732 103.881 0.675049 101.997 0.675049 100.033V63.9279H32.6768L35.6873 78.7415C35.9597 80.0729 36.6851 81.2694 37.7405 82.1284C38.796 82.9874 40.1166 83.456 41.4788 83.455H78.4958C79.8759 83.4538 81.2122 82.9723 82.2745 82.0934C83.3367 81.2145 84.0581 79.9933 84.3143 78.6405L87.0953 63.8875L118.908 63.9279Z"
        fill="#6F7DB6"
      />
      <path
        d="M26.6559 92.727H13.3989C13.2199 92.727 13.0482 92.656 12.9216 92.5297C12.795 92.4035 12.7239 92.2322 12.7239 92.0536C12.7239 91.875 12.795 91.7037 12.9216 91.5775C13.0482 91.4512 13.2199 91.3802 13.3989 91.3802H26.6559C26.8349 91.3802 27.0066 91.4512 27.1332 91.5775C27.2598 91.7037 27.3309 91.875 27.3309 92.0536C27.3309 92.2322 27.2598 92.4035 27.1332 92.5297C27.0066 92.656 26.8349 92.727 26.6559 92.727Z"
        fill="#4F5A84"
      />
      <path
        d="M35.8357 23.4662C35.6273 23.4651 35.4219 23.416 35.2357 23.3225C35.0495 23.229 34.8876 23.0938 34.7625 22.9275L25.5757 10.8072C25.36 10.5215 25.2669 10.162 25.3169 9.80775C25.3669 9.45354 25.5559 9.13365 25.8424 8.91846C26.1288 8.70327 26.4892 8.6104 26.8443 8.66028C27.1994 8.71016 27.52 8.8987 27.7357 9.18444L36.9157 21.3047C37.1313 21.5896 37.2246 21.9482 37.1753 22.3018C37.1259 22.6553 36.9379 22.9748 36.6525 23.1901C36.4178 23.3687 36.1309 23.4657 35.8357 23.4662Z"
        fill="#A8B1D0"
      />
      <path
        d="M79.3328 23.4662C79.038 23.4633 78.7519 23.3665 78.516 23.1901C78.2307 22.9748 78.0426 22.6553 77.9933 22.3017C77.9439 21.9482 78.0372 21.5896 78.2528 21.3047L87.4395 9.18441C87.6596 8.91204 87.9773 8.7358 88.3254 8.69299C88.6734 8.65018 89.0245 8.74416 89.3044 8.95504C89.5842 9.16592 89.7708 9.47715 89.8246 9.82284C89.8784 10.1685 89.7953 10.5216 89.5928 10.8072L80.4128 22.9275C80.287 23.0947 80.124 23.2305 79.9365 23.324C79.7491 23.4175 79.5424 23.4662 79.3328 23.4662Z"
        fill="#A8B1D0"
      />
      <path
        d="M58.6441 19.4261C58.286 19.4261 57.9427 19.2843 57.6895 19.0317C57.4363 18.7791 57.2941 18.4366 57.2941 18.0794V1.3467C57.2941 0.989533 57.4363 0.646993 57.6895 0.394438C57.9427 0.141883 58.286 0 58.6441 0C59.0021 0 59.3455 0.141883 59.5986 0.394438C59.8518 0.646993 59.994 0.989533 59.994 1.3467V18.0794C59.994 18.4366 59.8518 18.7791 59.5986 19.0317C59.3455 19.2843 59.0021 19.4261 58.6441 19.4261Z"
        fill="#A8B1D0"
      />
      <path
        d="M122.87 87.5826H83.9971C82.5469 87.5826 81.3713 88.7554 81.3713 90.202V107.918C81.3713 109.364 82.5469 110.537 83.9971 110.537H122.87C124.32 110.537 125.496 109.364 125.496 107.918V90.202C125.496 88.7554 124.32 87.5826 122.87 87.5826Z"
        fill="#DFAA21"
      />
      <path
        d="M122.87 111.217H83.997C83.1222 111.215 82.2837 110.868 81.665 110.251C81.0464 109.634 80.6981 108.797 80.6963 107.925V90.202C80.6981 89.3293 81.0464 88.4928 81.665 87.8757C82.2837 87.2586 83.1222 86.9111 83.997 86.9093H122.87C123.745 86.9111 124.584 87.2586 125.202 87.8757C125.821 88.4928 126.169 89.3293 126.171 90.202V107.925C126.169 108.797 125.821 109.634 125.202 110.251C124.584 110.868 123.745 111.215 122.87 111.217ZM83.997 88.256C83.4797 88.256 82.9835 88.461 82.6176 88.826C82.2518 89.1909 82.0463 89.6859 82.0463 90.202V107.925C82.0463 108.441 82.2518 108.936 82.6176 109.301C82.9835 109.666 83.4797 109.871 83.997 109.871H122.87C123.387 109.869 123.882 109.663 124.248 109.299C124.613 108.934 124.819 108.44 124.821 107.925V90.202C124.819 89.6864 124.613 89.1925 124.248 88.8279C123.882 88.4634 123.387 88.2578 122.87 88.256H83.997Z"
        fill="#4F5A84"
      />
      <path
        d="M88.02 94.5046H93.1905V96.0533H89.694V97.4674H92.9948V99.0094H89.694V100.558H93.3795V102.1H88.02V94.5046Z"
        fill="#1C2544"
      />
      <path
        d="M94.5203 96.8613H96.0728V97.5683C96.1272 97.4579 96.2002 97.3577 96.2888 97.2721C96.384 97.1654 96.4956 97.0744 96.6195 97.0027C96.7575 96.9205 96.9048 96.8549 97.0583 96.8075C97.234 96.7579 97.4157 96.733 97.5983 96.7334C97.9356 96.7272 98.2693 96.8034 98.5703 96.9556C98.8591 97.114 99.0852 97.3657 99.2115 97.6693C99.3611 97.3685 99.5957 97.118 99.8865 96.9489C100.179 96.814 100.495 96.7408 100.817 96.7338C101.139 96.7269 101.459 96.7863 101.756 96.9085C101.982 97.0209 102.177 97.1875 102.323 97.3933C102.471 97.6114 102.574 97.8562 102.627 98.1138C102.689 98.4037 102.718 98.6995 102.715 98.9958V102.073H101.102V99.0362C101.106 98.8175 101.053 98.6013 100.946 98.41C100.887 98.3197 100.804 98.2473 100.707 98.201C100.609 98.1546 100.5 98.1361 100.393 98.1474C100.232 98.1426 100.073 98.1748 99.927 98.2417C99.8052 98.296 99.7002 98.3821 99.6233 98.4908C99.5465 98.6055 99.4916 98.7334 99.4613 98.8679C99.4278 99.0159 99.412 99.1673 99.414 99.319V102.073H97.8008V99.319C97.8008 99.2248 97.8008 99.1103 97.8008 98.9756C97.794 98.8421 97.7666 98.7104 97.7198 98.5851C97.6807 98.4642 97.6081 98.3569 97.5105 98.2754C97.3873 98.1861 97.2374 98.141 97.0853 98.1474C96.9116 98.1413 96.7393 98.1808 96.5858 98.2619C96.4602 98.3301 96.3571 98.433 96.2888 98.5582C96.2187 98.6904 96.173 98.8341 96.1538 98.9824C96.1433 99.1483 96.1433 99.3147 96.1538 99.4807V102.073H94.5405L94.5203 96.8613Z"
        fill="#1C2544"
      />
      <path
        d="M104.024 96.8614H105.509V97.5347C105.583 97.4307 105.669 97.3358 105.766 97.2519C105.873 97.1464 105.996 97.0579 106.13 96.9893C106.274 96.9094 106.426 96.8439 106.583 96.794C106.756 96.745 106.935 96.72 107.116 96.72C107.476 96.7166 107.834 96.7829 108.169 96.9153C108.473 97.0467 108.748 97.2366 108.979 97.4741C109.207 97.7222 109.384 98.0128 109.499 98.3293C109.623 98.677 109.685 99.0441 109.681 99.4134C109.682 99.7676 109.628 100.12 109.519 100.457C109.412 100.777 109.25 101.076 109.04 101.339C108.84 101.597 108.586 101.808 108.297 101.959C107.988 102.116 107.645 102.194 107.298 102.188C106.976 102.193 106.656 102.14 106.353 102.033C106.065 101.934 105.815 101.748 105.638 101.501V104.504H104.024V96.8614ZM105.509 99.4672C105.493 99.8177 105.614 100.161 105.847 100.423C105.971 100.547 106.12 100.644 106.284 100.706C106.448 100.769 106.623 100.797 106.799 100.787C106.974 100.798 107.15 100.771 107.314 100.708C107.478 100.646 107.627 100.548 107.75 100.423C107.968 100.152 108.087 99.8149 108.087 99.4672C108.087 99.1196 107.968 98.7823 107.75 98.5111C107.627 98.3861 107.478 98.2889 107.314 98.2262C107.15 98.1635 106.974 98.1366 106.799 98.1475C106.623 98.1379 106.448 98.1654 106.284 98.228C106.12 98.2907 105.971 98.3871 105.847 98.5111C105.728 98.6445 105.637 98.8001 105.579 98.9689C105.521 99.1376 105.497 99.3162 105.509 99.4942V99.4672Z"
        fill="#1C2544"
      />
      <path
        d="M114.21 98.208H112.786V99.9452C112.775 100.075 112.775 100.206 112.786 100.336C112.794 100.445 112.826 100.551 112.88 100.646C112.935 100.732 113.015 100.8 113.11 100.841C113.24 100.894 113.381 100.919 113.522 100.915C113.609 100.915 113.724 100.915 113.866 100.915C113.982 100.904 114.092 100.86 114.183 100.787V102.134C114.002 102.199 113.814 102.242 113.623 102.262C113.433 102.286 113.241 102.297 113.049 102.295C112.797 102.298 112.545 102.269 112.3 102.208C112.084 102.158 111.88 102.067 111.699 101.938C111.524 101.812 111.384 101.643 111.294 101.447C111.19 101.219 111.14 100.97 111.146 100.72V98.208H110.113V96.8613H111.146V95.3193H112.759V96.8613H114.183L114.21 98.208Z"
        fill="#1C2544"
      />
      <path
        d="M118.037 102.921C117.929 103.198 117.821 103.447 117.72 103.662C117.633 103.866 117.505 104.05 117.342 104.201C117.175 104.357 116.974 104.472 116.755 104.538C116.451 104.619 116.138 104.655 115.823 104.645C115.413 104.648 115.005 104.584 114.615 104.457L114.831 103.11C115.067 103.216 115.322 103.271 115.58 103.272C115.731 103.276 115.882 103.256 116.026 103.211C116.134 103.177 116.231 103.117 116.309 103.036C116.387 102.957 116.451 102.866 116.498 102.767L116.66 102.39L116.775 102.087L114.473 96.8613H116.215L117.565 100.302L118.719 96.8613H120.373L118.037 102.921Z"
        fill="#1C2544"
      />
    </SvgIcon>
  );
}
